import { useLocation } from '@reach/router';
import { graphql, PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { ChangeEvent, useState } from 'react';

import { Box, Stack } from '../../components/Core';
import { Flex } from '../../components/Core/Flex';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import Layout from '../../components/Layout';
import PathologiesSection from '../../components/PathologiesSection';
import SEO from '../../components/SEO';
import without from '../../utils/helpers/without';
import { createImageWithArtDirection } from '../../utils/serializeImageForImageComponent';
import Filters from '../components/Blog/Filters';

type IPathologiesTemplate = PageProps<
  Queries.PagePathologiesQuery,
  IPageContext
>;

const Pathologies: FCWithClassName<IPathologiesTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const categories = data.allWpZoneCorps.nodes;
  const posts = data.allWpPathologie.nodes;
  const templateData =
    'contenuPathologies' in pageData.template
      ? pageData.template.contenuPathologies
      : null;
  if (!pageData || !pageContext.defaultLanguage || !templateData) return null;
  const location = useLocation();
  const categoryUrlParam = queryString.parse(location?.search)
    .category as string;
  const categoryFromUrlParam = categoryUrlParam?.replace('/', '');
  const [displayedCategoriesSlugs, setDisplayedCategoriesSlugs] = useState<
    string[]
  >(categoryFromUrlParam ? [categoryFromUrlParam] : []);

  const onFilterChange = (
    eventOrValue: ChangeEvent<HTMLInputElement> | string[]
  ) => {
    if (eventOrValue instanceof Array) {
      setDisplayedCategoriesSlugs(eventOrValue);
    } else {
      eventOrValue?.target?.checked
        ? setDisplayedCategoriesSlugs([
          ...displayedCategoriesSlugs,
          eventOrValue.target.name,
        ])
        : setDisplayedCategoriesSlugs(
          without(displayedCategoriesSlugs, eventOrValue.target.name)
        );
    }
  };
  const filteredPosts =
    displayedCategoriesSlugs.length === 0
      ? posts
      : posts.filter((post) =>
        post?.zonesCorps.nodes.some((category) => {
          return displayedCategoriesSlugs.includes(category.slug);
        })
      );
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: pageData.featuredImage?.node,
    mobileImage: templateData.pathologies.sectionHero?.imageMobile,
  });
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack justify="center" className="space-y-gap">
        <Hero
          type={HERO_TYPE.STATIC}
          heading={templateData.pathologies.sectionHero?.titre}
          backgroundImage={heroImageWithArtDirection}
        />
        <Box className="z-20">
          <Flex direction="column" align="center" className="relative z-10">
            <Filters
              categories={categories}
              displayedCategoriesSlugs={displayedCategoriesSlugs}
              onFilterChange={onFilterChange}
              title={templateData.pathologies.sectionTheme.titreTheme}
              defaultChecked={false}
            />
          </Flex>
          <PathologiesSection
            currentLanguageCode={pageContext.currentLanguage.code}
            displayedCategoriesSlugs={displayedCategoriesSlugs}
            title={undefined}
            description={undefined}
            pathologies={filteredPosts}
            readMoreLabel={data.allWpConfiguration.nodes[0]?.configuration?.textes?.lirePlus}
          />
        </Box>
      </Stack>
    </Layout>
  );
};

export default Pathologies;

export const query = graphql`
  query PagePathologies($id: String, $currentLanguageSlug: String) {
    wpPage(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      template {
        ... on WpTemplate_PagePathologies {
          contenuPathologies {
            pathologies {
              sectionTheme {
                titreTheme
              }
              sectionHero {
                titre
                imageMobile {
                  ...MediaItemFragment
                  localFile {
                    ...MobileHeroImage
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpZoneCorps(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        id
        name
        slug
      }
    }
    allWpPathologie(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        uri
        zonesCorps {
          nodes {
            categoryInformations {
              couleur
            }
            name
            slug
          }
        }
      }
    }
    allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          textes {
            lirePlus
          }
        }
      }
    }
  }
`;
